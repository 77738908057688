const { ethers } = require("ethers");
const  abi  = require("./FiatTokenV1.json");


export default class ContractHelper {
  constructor() {
      this.signer = null;
      this.provider = null;
      this.contract = null;
      this.initProvider();
  }

  initProvider() {
  if (window.ethereum) {
      this.switchNetwork(137)
      if (window.ethereum == null) {
          console.log("MetaMask not installed; using read-only defaults")
          this.provider = ethers.getDefaultProvider()
      } else {
          this.provider = new ethers.BrowserProvider(window.ethereum)
          this.signer =  this.provider.getSigner();
      }
      const contractAddress = abi.address;
      const contractABI = abi.abi;
      this.contract = new  ethers.Contract(contractAddress, contractABI, this.provider);
  } else {
      // If window.ethereum is not found then MetaMask is not installed
      alert('MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html');
  }

  }
  switchNetwork(chainId){
      try {
          // Try to switch to the Mumbai testnet
          window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x89' }], // Check networks.js for hexadecimal network ids
          });
      } catch (error) {
          // This error code means that the chain we want has not been added to MetaMask
          // In this case we ask the user to add it to their MetaMask
          if (error.code === 4902) {

              try {
                  console.log("chainId",chainId)
                  switch (chainId) {
                      // case 97:
                      //     window.ethereum.request({
                      //         method: 'wallet_addEthereumChain',
                      //         params: [
                      //             {
                      //                 chainId: '0x61',
                      //                 chainName: 'Polygon Mumbai Testnet',
                      //                 rpcUrls: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545'],
                      //                 nativeCurrency: {
                      //                     name: "Mumbai Matic",
                      //                     symbol: "tBNB",
                      //                     decimals: 18
                      //                 },
                      //                 blockExplorerUrls: ["https://testnet.bscscan.com/"]
                      //             },
                      //         ],
                      //     });
                      case 137:
                          window.ethereum.request({
                              method: 'wallet_addEthereumChain',
                              params: [
                                  {
                                      chainId: '0x89',
                                      chainName: 'Polygon Mumbai Testnet',
                                      rpcUrls: ['https://polygon-rpc.com'],
                                      nativeCurrency: {
                                          name: "Polygon Mainnet",
                                          symbol: "MATIC",
                                          decimals: 18
                                      },
                                      blockExplorerUrls: ["https://polygonscan.com/"]
                                  },
                              ],
                          });
                  }

              } catch (error) {
                  console.log(error);
              }
          }
          console.log(error);
      }
  }
  async getLoginAccount(){
      if (!this.signer  ||  this.signer === undefined) {
         this.initProvider()
      } else {
          return new Promise((resolve, reject) =>{
              this.signer.then((value)=>{
                  resolve(value.address)
              }).catch((err)=>{
                  reject(err)
              })
          })
      }

  }
async getContractBalanceOf(address ) {
    let decimals = await this.contract.decimals()
    let balance = await this.contract.balanceOf(address)
    return ethers.formatUnits(balance, decimals)
}
async symbol() {
    return this.contract.symbol()
}
async decimals() {
  return this.contract.decimals()
}
async  getMintRuneTotal(){
    return this.contract.mintRuneCount()
}
async  getMAX_RUNE_COUNT(){
    return this.contract.MAX_RUNE_COUNT()
}
async getRuneHolders(){
    return this.contract.getRuneHolders()
}

async getTokenHolders(){
    return this.contract.getTokenHolders()
}

    async getTotalTransfer()  {
    return this.contract.getTotalTransfer()

}

    async getDeployBlock() {
        return this.provider.getBlockNumber();
    }

    async blockNumberTime(blockNumber) {
        const block = await this.provider.getBlock(blockNumber);
        return block.timestamp
    }

    async getRunesName(blockNumber) {
        const block = await this.contract.symbol();
    }

    async mintRuneLogs(startBlock, endBlock) {
    let filter = this.contract.filters.RuneProtocolLogs
    let eventHistory  = await this.contract.queryFilter(filter, startBlock,endBlock)
    return eventHistory.reverse()
}
  mintRune (){
      return new Promise((resolve, reject) =>{
          const transaction = {
              to: abi.address,
              gasLimit: 500000,
              value: ethers.parseEther('0.1') ,
              data: this.contract.interface.encodeFunctionData('mintRune')
          };
          if (this.signer) {
              this.provider.getSigner().then(val => {
                  val.sendTransaction(transaction).then((tx)=>{
                      resolve(tx)
                  }).catch(error => {
                      reject(error)
                  });

              });
          } else {
              console.log("No signer available");
          }
      })

  }
}


