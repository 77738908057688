export const  Datetime =  {
    getDate(timestamp) {
        var date = new Date(timestamp * 1000); // 将时间戳转换为毫秒级别

        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要+1，并且补0
        var day = ('0' + date.getDate()).slice(-2); // 补0
        var hours = ('0' + date.getHours()).slice(-2); // 补0
        var minutes = ('0' + date.getMinutes()).slice(-2); // 补0
        var seconds = ('0' + date.getSeconds()).slice(-2); // 补0

        var formattedDate = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        return formattedDate
    }
}
