import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Runes from './pages/Runes/index.jsx'
import Marketplace from './pages/Marketplace/index.jsx'
import reportWebVitals from './utils/reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from "./Layout/layout";
import Usdp from "./pages/Tokens/index.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
        <BrowserRouter>
            <Layout>
            <Routes>
                <Route path = '/' element = {<Usdp/>} />
                <Route path = '/Runes' element = {<Runes/>} />
                <Route path = '/Marketplace' element = {<Marketplace/>} />
            </Routes>
            </Layout>
        </BrowserRouter>
);
reportWebVitals();
