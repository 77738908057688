import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Image, Button, notification,message, Space, Progress, Table} from 'antd';
import './index.css';
import ContractHelper from "./../../utils/myEthers";
import {Datetime} from "./../../utils/datetime";
const Context = React.createContext({
    name: 'Default',
});

export default function Usdp(){
    const [api, contextHolder] = notification.useNotification();
    const [data, setData] = useState('{"p":"prc-20","op":"mint","tick":"usdp","amt":"1000"}');
    const [mintData, setMintData] = useState();
    const [columns, setColumns] = useState([
        {
            title: 'Transaction',
            dataIndex: 'transactionHash',
            key: 'transactionHash',
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'method',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'blockNumber',
            dataIndex: 'blockmumber',
            key: 'blockmumber',
        },
        {
            title: 'DateTime',
            dataIndex: 'dateTime',
            key: 'dateTime',
        },
    ]);

    const [mintTotal,setMintTotal]=useState(0)
    const [currentMint,setCurrentMint]=useState(0)
    const [holders,setHolders] = useState(0)
    const [runeName,setRuneName] = useState("")
    const [columnsSource,setColumnsSource] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            width: 500,
            render: (text) => <Progress strokeColor={{
                '0%': '#00f0ff',
                '100%': '#ffb200',
            }} percent={text} />
        },
        {
            title: 'Total Supply',
            dataIndex: 'totalSupply',
            key: 'totalSupply',
        },
        {
            title: 'Minted',
            dataIndex: 'minted',
            key: 'minted',
        },
        {
            title: 'Holders',
            dataIndex: 'holders',
            key: 'holders',
        },
        {
            title: 'Block',
            dataIndex: 'deployBlock',
            key: 'deployBlock',
            width: 200,
            align: 'left'
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <div className="proportion">
                        <Button type="primary" size="large" onClick={mintClick}>
                            Mint
                        </Button>
                    </div>
                </Space>
            ),
        },
    ])
    const [dataSource,setDataSource] = useState([
        {
            name: runeName,
            progress: 100,
            totalSupply: mintTotal,
            minted: mintData,
            holders: holders,
            deployBlock: '0',
        },
    ])

    const [isModalOpen, setIsModalOpen] = useState(false)

    window.ethers = new ContractHelper()
    const initMint = ()=> {
        (async () => {
            eventLogs()
        })()
    }
    useEffect(()=> {
        (async () => {
            setTimeout(()=>{
                initMint()

            },100)
        })()
    }, [])

    const eventLogs = async () =>{
        let holders = await window.ethers.getRuneHolders()
        let runeName = await window.ethers.symbol()
        let block = await window.ethers.getDeployBlock()
        let count =  await window.ethers.getMAX_RUNE_COUNT()
        let currentMints = await window.ethers.getMintRuneTotal()
        let eventList = await window.ethers.mintRuneLogs(block-1000,block)
        let progress = (Number(currentMints.toLocaleString())/Number( count.toLocaleString().replace(/,/gi, '')*100)).toFixed(2)
        setDataSource([{name:runeName, progress: progress,
            totalSupply: count.toLocaleString(),
            minted: currentMints.toLocaleString(),
            holders:holders.toLocaleString(), deployBlock: block}])

        let history = []
        if (eventList.length >0 ){
            let sumCount = 0;

            for (const value of eventList) {
                if (sumCount >=5){
                    break
                }
                let eventtime =  await  window.ethers.blockNumberTime(value.blockNumber)
                let args = value.args.toObject()
                let content = args.content.replace("data:,","");
                let mintData =  JSON.parse(content);
                let historyItem = {
                    transactionHash:value.transactionHash,
                    method:mintData.op,
                    quantity:mintData.amt,
                    blockmumber:value.blockNumber,
                    dateTime:Datetime.getDate(eventtime),
                }
                history.push(historyItem)
                sumCount++
            }
            setMintData(history)
        }
    }
    const mintClick = async ()=>  {
         await window.ethers.mintRune().then(async (val)=>{
             message.success('success', [1.5])
             eventLogs()
         }).catch((error)=>{
             console.log(error)
         })
    }
    const joinOurCommunity = ()=>{
        window.open('https://t.me/prc20cyou', '_blank');
    }
    const handleOk = () => {
        setIsModalOpen(false);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }
    const usdpClick = async () =>{
        window.open('https://www.prc20.cyou', '_blank');

    }
    const twitterClick = async () =>{
        window.open('https://twitter.com/prc20cyou', '_blank');
    }
    const telegramClick = async () =>{
        window.open('https://t.me/prc20cyou', '_blank');
    }
        return (

            <div className="render">
                <div className="usdp-Content">
                    <div className="cont-tile">
                        Launchpad -> $usdp
                    </div>
                    <div className="usdp-title">$usdp
                        <div className="title-icon">
                            <Image width={22} onClick={usdpClick} preview={false}
                                   src={require('../../assets/img/usdp-icon.png')}/>
                        </div>
                        <div className="title-icon">
                            <Image width={22} onClick={twitterClick} preview={false}
                                   src={require('../../assets/img/twitter-icon.png')}/>
                        </div>
                        <div className="title-icon">
                            <Image width={22} onClick={telegramClick} preview={false}
                                   src={require('../../assets/img/telegram-icon.png')}/>
                        </div>
                    </div>
                    <div className="title-notes">The first runes protocol is deployed on the polygon network, innovatively defining the inscription standard.</div>
                    <div className="details-table">
                        <Table rowKey="name" dataSource={dataSource} columns={columnsSource} pagination={false}/>
                    </div>
                </div>

                <div className="join-gr">
                    <div>Join our community</div>
                    <Button onClick={joinOurCommunity} style={{border: '1px solid #fec715',
                        backgroundColor: '#fec715',
                        color: '#ffffff'}} size="large">
                        Join Group
                    </Button>
                </div>
                <div className="join-table">
                    {!mintData ? (<div></div>): (<div className="details-table">
                        <Table  rowKey="detailsNameRowKey" pagination={false} dataSource={mintData} columns={columns}/>
                    </div>)}
                </div>
                <div className="join-table">
                    <div style={{padding:'16px',}}>
                        <div style={{ height:'48px',lineHeight:'48px',borderBottom:'1px solid #666666'}}>INTRODUCTION</div>
                        <div>What is $usdp? $usdp is the rune deployed on the polygon mainnet, inspired by the Runes protocol recently proposed by Casey, the founder of the ordinal protocol brc20, but with some differences.The implementation is to take advantage of the EVM's advantages that are lacking in the Bitcoin network, eliminate cumbersome and inefficient centralized retrieval, and play the function of open-source smart contracts to achieve intelligent retrieval. Giving smart contracts the ability to call and operate inscriptions.</div>
                        <div> <p className="cont-t">Tokenomics</p>
                            <p>· Total Supply : {mintTotal} Mints</p>
                            <p>· Each Mints : 10 $usdp</p>
                            <p>· Distribute : Fair launch</p>
                        </div>
                        <div> <p className="cont-t">Reward</p>
                            <p>. The first 500,000 minted users will receive a USDP token airdrop each time they mint.</p>
                            <p>· The reward mechanism is a way to incentivize user participation in activities. In this case, for the first 500,000 users who perform a Mint operation, each Mint operation will receive a reward airdrop of 20 USDP token. This reward mechanism aims to encourage active user participation and provide them with a certain benefit to drive the growth and development of the platform. By doing so, it can attract more users to participate and promote community engagement.</p>
                        </div>
                        <div> <p className="cont-t">Social media</p>
                            <p>· Website : https://www.prc20.cyou/</p>
                            <p>· X : https://twitter.com/prc20cyou</p>
                            <p>·Telegram : https://t.me/prc20cyou</p>
                        </div>
                        <div> <p className="cont-t">How to Mint $usdp?</p>
                            <p>1. Select polygon mainnet to connect wallet to prc20.cyou</p>
                        </div>
                        <div> <p className="cont-t">Advantages of $usdp</p>
                            <p>1. $usdp is the first runes protocol on polygon, and it will be launched fairly on the prc20.cyou inscription trading market as the first lanchpad project.</p>
                            <p>2. Revolutionary inscription molding method, no one can get it for free! Using the characteristics of the EVM environment, users are required to donate 0.1 MATIC to trigger the EVM smart contract to Mint usdp.</p>
                            <p>3. Users do not need to inscribe the data themselves, because the smart contract will autoBNBally inscribe the data in the transaction log for you.</p>
                            <p>4. As the first rune protocol on polygon, $usdp will set the standard for all EVM chain rune protocols and will become the central currency of the polygon rune ecosystem, just like $sats (10,000x) on Bitcoin.</p>
                            <p>5. Compared with the inscriptions on Bitcoin, the prc-20 protocol gives smart contracts the ability to call and operate inscriptions/runes. Using the prc-20 protocol, functions such as burning, staking, and locking can be achieved. It may derive applications such as "rune games" and "rune mining"</p>
                        </div>
                        <div> <p className="cont-t">Opportunities for $usdp</p>
                            <p>   1. Name and Symbol: Name the stablecoin as USD-Pegged (USDP) with the symbol USDP.</p>
                            <p>   2. Technical Parameters: Specify the block confirmation time as 15 seconds, processing speed of 1,000 transactions per second. </p>
                            <p>   3. Objectives and Functions: Describe USDP as a fast and convenient digital payment tool for cross-border payments and fund transfers. </p>
                            <p>   4. Security: Adopt the SHA-256 encryption algorithm to ensure transaction and account security, and utilize multi-signature technology to enhance transaction reliability. </p>
                            <p>   5. Issuance and Distribution: Provide a detailed explanation of the ICO issuance process, how investment funds will be handled, and the method of distributing stablecoins to investors. </p>
                            <p>   6. User Guide: Offer wallet setup guidelines, instructions on how to transfer USDP, and necessary security precautions. </p>
                            <p>   7. Legal Compliance: Emphasize the legality and compliance of USDP, including KYC (Know Your Customer) verification and measures demonstrating compliance with financial regulations. </p>
                            <p>   8. Market Information: Provide market information about USDP, including listing exchanges, trading pairs, and market price trends. </p>
                            <p>The inscription track has produced many rich coin bells, and the upgraded version of the inscription protocol, the rune track, is a new track in the hotspot. The rune $cook, which is on fire in the BTC ecosystem, has also made a profit of 50 times the cost. But the runes on each EVM chain are still in a blank state. As the first rune protocol on polygon, $usdp will undertake the capital of stepping on the air, has unlimited potential, and will become the leader of EVM runes.</p>
                        </div>
                    </div>
                </div>
            </div>
        )

}
