import React from 'react';
import { Button, Table, Progress, Image } from 'antd';
import './index.css';

class Marketplace extends React.Component {
    state = {
        statue: true,
        buttonTokenStyle: {
            border: '1px solid #fec715',
            backgroundColor: '#000000',
            color: '#fec715',
        },
        buttonNftStyle: {
            border: '1px solid #7c7c7c',
            backgroundColor: '#000000',
            color: '#7c7c7c'
        },
        columns:[
            {
                title: 'Tick',
                dataIndex: 'tick',
                key: 'tick',
                render: (text) =><div>{text}<Image width={28}
                    src={require('../../assets/img/token-icon.png')}
                /></div>
            },
            {
                title: 'Floor Price',
                dataIndex: 'floorPrice',
                key: 'floorPrice',
                render: (text) =>`$${text}`
            },
            {
                title: '24h Volume',
                dataIndex: 'volume',
                key: 'volume',
                render: (text) =><div><Image width={16}
                   src={require('../../assets/img/volume-icon.png')}
                /> {text}</div>
            },
            {
                title: '24h Sales',
                dataIndex: 'sales',
                key: 'sales'
            },
            {
                title: 'Holders',
                dataIndex: 'holders',
                key: 'holders'
            },
            {
                title: 'Total Volume',
                dataIndex: 'totalVolume',
                key: 'totalVolume',
                render: (text) =><div><Image width={16}
                    src={require('../../assets/img/volume-icon.png')}
                /> {text}</div>
            },
            {
                title: 'Total Sales',
                dataIndex: 'totalSales',
                key: 'totalSales'
            },
            {
                title: 'Market Cap',
                dataIndex: 'marketCap',
                key: 'marketCap',
                render: (text) =>`$${text}`
            },
            {
                title: 'Listed',
                dataIndex: 'listed',
                key: 'listed'
            }
        ],
        dataSource:[
            {
                tick: 'POOK',
                floorPrice: '0.000123',
                volume: '84,528.49',
                sales: '478',
                holders: '23,047',
                totalVolume: '1,357,698.22',
                totalSales: '2,915',
                marketCap: '1,260,408',
                listed: '1305',
            },
        ],
    }

    tokensClick = () =>{
        this.setState({
            statue: true,
            dataSource:[
                {
                    tick: 'POOK',
                    floorPrice: '0.000123',
                    volume: '84,528.49',
                    sales: '478',
                    holders: '23,047',
                    totalVolume: '1,357,698.22',
                    totalSales: '2,915',
                    marketCap: '1,260,408',
                    listed: '1305',
                },
            ]
        })

    }

    nftClick = () =>{
        this.setState({
            statue: false,
            dataSource:[
                {
                    tick: 'NFT',
                    floorPrice: '0.000123',
                    volume: '84,528.49',
                    sales: '478',
                    holders: '23,047',
                    totalVolume: '1,357,698.22',
                    totalSales: '2,915',
                    marketCap: '1,260,408',
                    listed: '1305',
                },
            ]
        })
    }

    onRowClick(record,index){
        console.log('点击了这行数据',record,index)
    }

    render() {
        return (
            <div className="render">
                <div className="Marketplace-Content">
                    <div className="cont-tag">
                        <Button className="tag-left" style={this.state.statue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.tokensClick}>Tokens</Button>
                        <Button style={!this.state.statue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.nftClick}>NFTs</Button>
                    </div>
                    <div className="content-table">
                        <div className="table-title">Trending Tokens</div>
                        <Table onRow={(record, index) => {
                            return {
                                onClick:()=>{this.onRowClick(record, index)},
                            }}} rowKey="nameMarketplace" dataSource={this.state.dataSource} columns={this.state.columns} pagination={false}/>
                    </div>
                </div>
            </div>
        )
    }
}


export default Marketplace;
