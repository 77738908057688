import React from 'react';
import { Button, Table, Progress } from 'antd';
import './index.css';
import {ReloadOutlined} from "@ant-design/icons";

class Runes extends React.Component {

    state = {
        statue: true,
        holdersStatue: true,
        buttonTokenStyle: {
            border: '1px solid #fec715',
            backgroundColor: '#000000',
            color: '#fec715',
        },
        buttonNftStyle: {
            border: '1px solid #7c7c7c',
            backgroundColor: '#000000',
            color: '#7c7c7c'
        },
        showElem: false,
        detailsColumns:[
            {
                title: 'Rank',
                render:(text,record,index)=>`${index+1}`,
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Progress',
                dataIndex: 'progress',
                key: 'progress',
                width: 500,
                render: (text) => <Progress strokeColor={{
                    '0%': '#00f0ff',
                    '100%': '#ffb200',
                }} percent={text} />
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                width: 200,
                align: 'right'
            },
        ],
        columns:[
            {
                title: '#',
                render:(text,record,index)=>`${index+1}`,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Progress',
                dataIndex: 'progress',
                key: 'progress',
                width: 500,
                render: (text) => <Progress strokeColor={{
                    '0%': '#00f0ff',
                    '100%': '#ffb200',
                }} percent={text}  showInfo={false} />
            },
            {
                title: 'Total Supply',
                dataIndex: 'totalSupply',
                key: 'totalSupply',
            },
            {
                title: 'Minted %',
                dataIndex: 'minted',
                key: 'minted',
            },
            {
                title: 'Holders',
                dataIndex: 'holders',
                key: 'holders',
            },
            {
                title: 'Deploy Block',
                dataIndex: 'deployBlock',
                key: 'deployBlock',
                width: 200,
                align: 'right'
            },
        ],
        dataSource:[
            {
                name: 'POOk',
                progress: 100,
                totalSupply: '10,240,000,000',
                minted: '100%',
                holders: '23,111',
                deployBlock: '51268782',
            },
        ],
        dataDetailsSource:[
            {
                address: '0x0912D5E42b82C42b894dBb3ef4B3F18a0Edba409',
                progress: 6.92,
                value: '708,231,213',
            },
        ]
    }

    holdersClick = () =>{
        this.setState({
            holdersStatue: true,
            dataDetailsSource:[
                {
                    address: '0x0912D5E42b82C42b894dBb3ef4B3F18a0Edba409',
                    progress: 6.92,
                    value: '708,231,213',
                },
            ]
        })

    }

    transferClick = () =>{
        this.setState({
            holdersStatue: false,
            dataDetailsSource:[
                {
                    address: '0x0912D5E42b82C42b894dBb3ef4B3F18a0Edba409',
                    progress: 1.02,
                    value: '231,213',
                },
            ]
        })

    }

    tokensClick = () =>{
        this.setState({
            statue: true,
            dataSource:[
                {
                    name: 'POOk',
                    progress: 100,
                    totalSupply: '10,240,000,000',
                    minted: '100%',
                    holders: '23,111',
                    deployBlock: '51268782',
                },
            ]
        })

    }

    nftClick = () =>{
        this.setState({
            statue: false,
            dataSource:[
                {
                    name: 'ALPHABOX',
                    progress: 15,
                    totalSupply: '99,999',
                    minted: '15%',
                    holders: '774',
                    deployBlock: '51268782',
                },
            ]
        })
    }

    onRowClick(record,index){
        console.log('点击了这行数据',record,index)
        this.setState({
            showElem: true
        })
    }

    render(){
        return (
            <div className="render">
                {
                    this.state.showElem?(
                        <div className="Runes-Content">
                            <div className="cont-title">POOK</div>
                            <div className="cont-progress">
                                <Progress
                                    format={(percent) => percent === 100 ? '100%' : `${percent}%`}
                                    strokeColor={{
                                        '0%': '#00f0ff',
                                        '100%': '#ffb200',
                                    }}
                                    percent={this.state.dataSource[0].progress} />
                            </div>
                            <div className="cont-details">
                                <div className="details-title">Overview</div>
                                <div className="details-row">
                                    <div>Tx ID</div>
                                    <div>0xf9fe9582a7f57c7cac6d1ba60c4d425c62a97766fb0e95922810ddd93c7664c2</div>
                                </div>
                                <div className="details-row">
                                    <div>Rune Number</div>
                                    <div>1</div>
                                </div>
                                <div className="details-row">
                                    <div>Total Supply</div>
                                    <div>10,240,000,000</div>
                                </div>
                                <div className="details-row">
                                    <div>Minted</div>
                                    <div>10,240,000,000</div>
                                </div>
                                <div className="details-row">
                                    <div>Limit Per Mint</div>
                                    <div>1,000</div>
                                </div>
                                <div className="details-row">
                                    <div>Decimal</div>
                                    <div></div>
                                </div>
                                <div className="details-row">
                                    <div>Deploy By</div>
                                    <div>0x4E0050Fb270922B2e59952FC2cf420C09d98AE14</div>
                                </div>
                                <div className="details-row">
                                    <div>Deploy Time</div>
                                    <div>2023/12/18 18:48:47</div>
                                </div>
                                <div className="details-row">
                                    <div>Minter</div>
                                    <div>0xF9C4c674188089A7A5C608510360155147b9607b</div>
                                </div>
                                <div className="details-row">
                                    <div>Holders</div>
                                    <div>23,095</div>
                                </div>
                            </div>
                            <div className="details-table">
                                <div className="details-tag">
                                    <Button className="tag-left" style={this.state.holdersStatue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.holdersClick}>Holders</Button>
                                    <Button style={!this.state.holdersStatue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.transferClick}>Transfer</Button>
                                </div>
                                <Table onRow={(record, index) => {
                                    return {
                                        onClick:()=>{this.onRowClick(record, index)},
                                    }}} rowKey="detailsName" dataSource={this.state.dataDetailsSource} columns={this.state.detailsColumns}/>
                            </div>
                        </div>
                    ):<div className="Runes-Content">
                        <div className="cont-tag">
                            <Button className="tag-left" style={this.state.statue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.tokensClick}>Tokens</Button>
                            <Button style={!this.state.statue ? this.state.buttonTokenStyle : this.state.buttonNftStyle} onClick={this.nftClick}>NFTs</Button>
                        </div>
                        <div className="cont-Refresh">Refresh <ReloadOutlined style={{color: '#666666'}} onClick={ this.refreshClick }/></div>
                        <div className="content-table">
                            <Table onRow={(record, index) => {
                                return {
                                    onClick:()=>{this.onRowClick(record, index)},
                                }}} rowKey="name" dataSource={this.state.dataSource} columns={this.state.columns} pagination={false}/>
                        </div>
                    </div>
                }

            </div>
        )
    }
}


export default Runes;
