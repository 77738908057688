import {Link} from "react-router-dom";
import {Button, Image, message} from "antd";
import './layout.css'
import {useState,useEffect} from "react";
import  ContractHelper from "../utils/myEthers";
export default function Layout({children}) {


    const [account, setAccount] = useState("Connect Wallet");
    const [firstName, setFirstname] = useState();
    const [balanceValue, setBalanceValue] = useState("0.00");
    window.ethers = new ContractHelper();

    useEffect(() => {
        window.ethers.getLoginAccount().then( (address)=>{
            window.ethers.getContractBalanceOf(address).then((value)=>{
                setBalanceValue(value.toLocaleString())
            })
            address = address.slice(0, 4) + "******" + address.slice(-3);
            setAccount(address)
        })
    }, [])

    const Connection = async ()=>{
        window.ethers = new ContractHelper();
        let address = await window.ethers.getLoginAccount()
        if (address.length >0 || address != undefined){
            address = address.slice(0, 4) + "******" + address.slice(-3);
            setAccount(address)
        }
    }
    const ShowWaite = () =>{
        message.warning('Coming soon!', [1.5])
    }

    return (
        <div>
            <header className="App-header">
                <nav>
                    <Link className ='link' to='/'> PRC20.CYOU</Link>
                    <Link className ='link1' to='/'> PRC20</Link>
                    <Link className ='link' onClick={ShowWaite} to='#'> Marketplace</Link>
                    {/*<Link className ='link' to='/Launchpad'> Launchpad</Link>*/}
                    {/*<Link className ='link' to='/Bridge'> Bridge</Link>*/}
                {/*    /!*<Link className ='link2' to='/MyAssets'> My Assets</Link>*!/*/}
                    <Image width={22} preview={false} src={require('../assets/img/usdp-icon.png')}/>
                    <span style={{color: '#fff', marginRight: '20px', paddingLeft:'8px', fontSize: '16px'}}>{balanceValue}</span>
                    <Button onClick={Connection}>{account}</Button>
                </nav>
            </header>
            {children}
        </div>
    );
}
